import React, { useContext, useEffect, useState } from 'react'
import { BlocksContext } from "../context/blocksContext"
import { TextField } from '@material-ui/core'
import InputMask from 'react-input-mask/lib/react-input-mask.development'
import { useSendForm } from '../hooks/send-from.hook'

export const Banner_action = () => {
    const [loadImage, setLoadImage] = useState(false)
    const sendForm = useSendForm();
    const loaded = useContext(BlocksContext)

    useEffect(()=>{
        if (loaded.blocks > 3) {
            setLoadImage(true)
        }
    },[loaded.popup])


    return (
        <section className="banner_action">
            {/*<div className="banner_action_img">*/}
            {/*    <img src={loadImage?"images/banner_action.jpg":""} />*/}
            {/*    <img src={loadImage?"images/banner_action_mobile.jpg":""} />*/}
            {/*</div>*/}
            {/*<div className="banner_action_content">*/}
            {/*<div className="bcc__title">*/}
            {/*    <span>Проще купить!</span>*/}
            {/*    <div className="dec1"><img src="/images/dec1.svg" /></div>*/}
            {/*    <div className="dec2"><img src="/images/dec2.svg" /></div>*/}
            {/*    </div>*/}
            {/*<form className="bcc__form">*/}
            {/*    <div className="bcc__form_title">Квартиры от 9 000 руб/мес</div>*/}
            {/*    <input type="text" name="name" placeholder="Ваше имя"/>*/}
            {/*    <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>*/}
            {/*        <input type="text" name="phone" placeholder="Ваш телефон"/>*/}
            {/*    </InputMask>*/}
            {/*    <input type="hidden" class="text" value="Квартиры от 9 000 руб/мес"/>*/}
            {/*    <button className="bcc__btn" celtype="getPresentation" onClick={sendForm.sendForm}>Узнать подробнее​</button>*/}
            {/*</form>*/}
            {/*</div>*/}
        </section>
    )
}
