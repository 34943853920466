import { TextField } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask/lib/react-input-mask.development'
import { BlocksContext } from "../context/blocksContext"
import { useSendForm } from '../hooks/send-from.hook'
import parse from 'html-react-parser';

export const Header = () => {
    const blocks = useContext(BlocksContext)
    const [state, setState] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [title, setTitle] = useState("<span>Квартиры <br /> с видом на море</span>")
    const [swiper, setSwiper] = useState(null)
    const [swiped, setSwiped] = useState(false)

    const sendForm = useSendForm()

    const showPopup = (e) => {
        e.preventDefault()
        console.log(blocks.blocks)
        if (blocks.blocks < 4) {
            blocks.setBlocks(24)
            let target = e.currentTarget
            setTimeout(() => {
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display = "block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display = "none"
                });
                document.querySelector('.' + target.getAttribute('data')).style.display = "block"
            }, 1800)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display = "block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display = "none"
            });
            console.log(e.currentTarget.getAttribute('data'))
            document.querySelector('.' + e.currentTarget.getAttribute('data')).style.display = "block"
        }
    }

    useEffect(() => {

        if (swiper) {
            setTimeout(() => {
                console.log(swiper)
                swiper.slideNext()
                if (window.scrollY === 0) {
                    window.scrollTo(0, 1);
                }
                setSwiped(true);
            }, 30000)
        }
    }, [swiper])

    const getTitle = () => {
        const headers = { 'Content-Type': 'application/json' }
        fetch("/titles.php" + window.location.search, headers)
            .then(res => res.json())
            .then((result) => {
                console.log(result.title)
                setTitle(result.title)
            })
    }

    useEffect(() => {
        getTitle()
    }, [])

    return (
        <React.Fragment>
            <section className='header_main showed'>
                <section className="header showed">
                    <div className="cw_main">
                        <div className="hd_inner">
                            <h1>
                                {parse(title)}
                                <div className="dec1"><img src="/images/dec1.svg" /></div>
                                <div className="dec2"><img src="/images/dec2.svg" /></div>
                            </h1>
                            <div className="hd_loc">Владивосток</div>
                            <div className='winner'><img src="/images/winner.png" /></div>
                            <div className='header_plan'>
                                <div className='hp__title'>Планировка месяца</div>
                                <div className='hp__img'>
                                    <img src='/images/header_plan_60.3.png'/>
                                </div>
                                <div className='hp__info'>Общая площадь: 60,3 м²</div>
                                <div onClick={showPopup} data="pu_fs_plan" className='hp__btn'>Узнать подробнее</div>
                            </div>
                        </div>
                        <img src="/images/dom_rf_hd.jpg" className="dom_rf_hd" />
                    </div>
                </section>
                {/* <CountDown /> */}

                {/* pu action */}
                <div className={!state ? 'pu_action' : 'pu_action pu_action__open'}>
                    <div className='pu_action__btn' onClick={() => { setState(!state) }}></div>
                    <div className='pu_action__img'><img src={loaded ? "/images/pu_action_img.jpg" : ""} /></div>
                    <form className="pu_form_action">
                        <div className='pu_action__title'>Узнайте подробнее об условиях акции</div>
                        <div className='pu_form__in'>
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя" className="pu_name" />
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                    <TextField name="phone" label="Ваш телефон" className="pu_phone" />
                                </InputMask>
                            </div>
                        </div>
                        <input type="hidden" class="text" value="Узнать подробнее об условиях акции" />
                        <div className='align_center'>
                            <button className="pu_btn" onClick={sendForm.sendForm} celtype="getAction">Узнать подробнее</button>
                        </div>
                    </form>
                </div>
            </section>
        </React.Fragment>
    )
}
